import { JsonObject, JsonProperty } from 'json2typescript';
import { BaseObject } from './base-object-model';
import { SupplierPaymentTerms, SupplierPaymentTermsConverter } from './supplier-payment-terms-model';
import { Status, StatusConverter } from './status-model';

@JsonObject('SupplierDTO')
export class SupplierDTO extends BaseObject {
  @JsonProperty('id', Number, true) id: number = undefined;
  @JsonProperty('name', String, false) name: string = undefined;
  @JsonProperty('businessName', String, true) businessName: string = undefined;
  @JsonProperty('phone', String, true) phone: string = undefined;
  @JsonProperty('code', String, false) code: string = undefined;
  @JsonProperty('iban', String, true) iban: string = undefined;
  @JsonProperty('minimalValue', Number, true) minimalValue: number = undefined;
  @JsonProperty('minimalShipmentValue', Number, true) minimalShipmentValue: number = undefined;
  @JsonProperty('shipsToAmazon', Boolean, true) shipsToAmazon: boolean = undefined;
  @JsonProperty('transit', Boolean, true) transit: boolean = undefined;
  @JsonProperty('canPickupToday', Boolean, true) canPickupToday: boolean = undefined;
  @JsonProperty('acceptBindingOrders', Boolean, true) acceptBindingOrders: boolean = undefined;
  @JsonProperty('score', Number, true) score: number = undefined;
  @JsonProperty('takesBackorder', Boolean, true) takesBackorder: boolean = undefined;
  @JsonProperty('takesFirstorder', Boolean, true) takesFirstorder: boolean = undefined;
  @JsonProperty('takesSoftFirstorder', Boolean, true) takesSoftFirstorder: boolean = undefined;
  @JsonProperty('updatedCatalog', Boolean, true) updatedCatalog: boolean = undefined;
  @JsonProperty('uniqueSupplier', Boolean, true) uniqueSupplier: boolean = undefined;
  @JsonProperty('ssccPrefix', String, true) ssccPrefix: string = undefined;
  @JsonProperty('sendSSCC', Boolean, true) sendSSCC: boolean = undefined;
  @JsonProperty('sendsAsn', Boolean, true) sendsAsn: boolean = undefined;
  @JsonProperty('asnPrefix', String, true) asnPrefix: string = this.code;
  @JsonProperty('jimboMessages', [String], true) jimboMessages: string[] = undefined;
  @JsonProperty('shipmentDocumentationReceivers', String, true) shipmentDocumentationReceivers: string = undefined;
  @JsonProperty('packingListErrorReceivers', String, true) packingListErrorReceivers: string = undefined;
  @JsonProperty('defaultTransitWarehouse', Number, true) defaultTransitWarehouse: number = undefined;
  @JsonProperty('packingListFieldsPosition', Object, true) packingListFieldsPosition: Map<string, number> = undefined;
  @JsonProperty('expiringShipmentAlertReceivers', String, true) expiringShipmentAlertReceivers: string = undefined;
  @JsonProperty('expiringShipmentAlertDaysBefore', Number, true) expiringShipmentAlertDaysBefore: number = undefined;
  @JsonProperty('expiringShipmentAlertTemplateWithDestination', Boolean, true)
  expiringShipmentAlertTemplateWithDestination: boolean = undefined;
  @JsonProperty('paymentCategoryThreshold', Number, true) paymentCategoryThreshold: number = undefined;
  @JsonProperty('paymentExecutiveThreshold', Number, true) paymentExecutiveThreshold: number = undefined;
  @JsonProperty('categoryManagerReceivers', String, true) categoryManagerReceivers: string = undefined;
  @JsonProperty('executiveManagerReceivers', String, true) executiveManagerReceivers: string = undefined;
  @JsonProperty('paymentRemittanceReceivers', String, true) paymentRemittanceReceivers: string = undefined;
  @JsonProperty('downconfirmThreshold', Number, true) downconfirmThreshold: number = undefined;
  @JsonProperty('paymentTerms', SupplierPaymentTermsConverter, true) paymentTerms: SupplierPaymentTerms = undefined;
  @JsonProperty('discount', Number, true) discount: number = undefined;
  @JsonProperty('shippingCosts', Number, true) shippingCosts: number = undefined;
  @JsonProperty('shipmentDocumentMethod', StatusConverter, true) shipmentDocumentMethod: Status = undefined;
  @JsonProperty('parentSupplierId', Number, true) parentSupplierId: number = undefined;
}
