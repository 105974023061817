import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { VendorsService } from '@container/configuration/vendors/vendors.service';
import { LotFilters, lotFiltersMap } from '@models/filters/lot-filters-model';
import { MatChipsData } from '@models/mat-chips-data-model';
import { Po } from '@models/po-model';
import { Vendor } from '@models/vendor-model';
import { LotsService } from '@shared/lots.service';
import { PosService } from '@shared/pos.service';
import { Observable, Subject } from 'rxjs';
@Component({
  selector: 'app-lots-list-filters',
  templateUrl: './lots-list-filters.component.html',
  styleUrls: ['./lots-list-filters.component.css'],
})
export class LotsListFiltersComponent implements OnInit {
  constructor(private vendorsService: VendorsService, private posService: PosService, private lotsService: LotsService) {}

  vendors: Vendor[] = [];

  filterForm: FormGroup;
  public openFilter = false;

  pos: Po[] = [];

  filteredPos: Observable<Po[]>;

  @Output() onLotFilter = new Subject<LotFilters>();

  filtersMap: MatChipsData[] = [];

  ngOnInit() {
    this.filterForm = new FormGroup({
      dateFrom: new FormControl(),
      dateTo: new FormControl(),
      po: new FormControl(),
      vendor: new FormControl(),
      status: new FormControl(),
      type: new FormControl(),
    });
    if (this.lotsService.lotFilters !== null) {
      this.filterForm.patchValue(this.lotsService.lotFilters);
      this.updateFiltersMap();
    }

    this.vendorsService.getVendors().subscribe((vendorsResp: Vendor[]) => {
      this.vendors = vendorsResp;
      this.setVendor();
    });
  }

  setVendor() {
    if (this.lotsService.lotFilters !== null && this.lotsService.lotFilters.vendor !== null) {
      this.filterForm.controls['vendor'].patchValue(this.vendors.find((it) => it.id === this.lotsService.lotFilters.vendor));
      this.updateFiltersMap();
    }
  }

  onSubmitFilter() {
    this.saveFiltersToLotService();
    this.updateFiltersMap();
    this.onLotFilter.next(this.lotsService.lotFilters);
  }

  resetFilters() {
    this.filterForm.reset();
    this.saveFiltersToLotService();
    this.updateFiltersMap();
    this.onLotFilter.next(this.lotsService.lotFilters);
  }

  openEvent() {
    this.openFilter = this.openFilter !== true;
  }

  closeevent() {
    this.openFilter = false;
  }

  saveFiltersToLotService() {
    this.lotsService.lotFilters.dateFrom = this.filterForm.value.dateFrom;
    this.lotsService.lotFilters.dateTo = this.filterForm.value.dateTo;
    this.lotsService.lotFilters.po = this.filterForm.value.po;
    this.lotsService.lotFilters.vendor = this.filterForm.value.vendor ? this.filterForm.value.vendor.id : null;
    this.lotsService.lotFilters.status = this.filterForm.value.status;
    this.lotsService.lotFilters.type = this.filterForm.value.type;
    this.lotsService.lotFilters.pageNumber = null;
    this.lotsService.lotFilters.pageSize = null;
    this.lotsService.lotFilters.sortBy = null;
    this.lotsService.lotFilters.sortDirection = null;
  }

  updateFiltersMap() {
    this.filtersMap = lotFiltersMap(
      this.lotsService.lotFilters.dateFrom,
      this.lotsService.lotFilters.dateTo,
      this.filterForm.value.po,
      this.filterForm.value.vendor,
      this.filterForm.value.status,
      this.filterForm.value.type
    );
  }
}
