import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Status } from '../../../../models/status-model';
import { AuthorizationService } from '../../../../core/authorization.service';
import { isNilty } from '../../../../core/utils.service';
import { Permissions } from '../../../../models/permission-model';
import { StockLocation } from '../../../../models/stock-location-model';
import { StockLocationWithoutSupplier } from '../../../../models/stock-location-without-supplier-model';
import { Supplier } from '../../../../models/supplier-model';
import { GenericConfirmationModalComponent } from '../../../modal/generic-confirmation-modal/generic-confirmation-modal.component';
import { GenericErrorModalComponent } from '../../../modal/generic-error-modal/generic-error-modal.component';
import { InventoryService } from '../../inventory.service';

@Component({
  selector: 'app-supplier-edit',
  templateUrl: './supplier-edit.component.html',
  styleUrls: ['./supplier-edit.component.css'],
})
export class SupplierEditComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private inventoryService: InventoryService,
    private router: Router,
    private dialog: MatDialog,
    private authorizationService: AuthorizationService,
    private snackBar: MatSnackBar,
    private location: Location
  ) {}

  supplierForm: FormGroup;
  stockLocForm: FormGroup;
  editMode = false;
  supplierId: number;
  currentSupplier = new Supplier();
  defaultStockLoc = new StockLocation();
  hasSupplierThresholdUpdate: Observable<boolean>;
  hasAdministration: Observable<boolean>;

  showJimboMessagesSelect = false;
  jimboMessageTypes: string[] = [];
  selectedMessageTypes: string[] = [];
  canSaveMessages = false;

  transitWarehouse: StockLocationWithoutSupplier[] = [];

  packingListFields: string[] = [];
  paymentTerms: Status[] = [];

  shipmentDocumentationReceivers: string[] = [];
  packingListErrorReceivers: string[] = [];
  expiringShipmentAlertReceivers: string[] = [];
  categoryManagerReceivers: string[] = [];
  executiveManagerReceivers: string[] = [];
  paymentRemittanceReceivers: string[] = [];

  receiverEmailInput = new FormControl('', Validators.email);
  receiverPackingListEmailInput = new FormControl('', Validators.email);
  receiverExpiringShipmentAlert = new FormControl('', Validators.email);
  receiverCategoryManager = new FormControl('', Validators.email);
  receiverExecutiveManager = new FormControl('', Validators.email);
  receiverPaymentRemittance = new FormControl('', Validators.email);
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  shipmentDocumentMethods: Status[] = [];

  suppliers: Supplier[] = [];

  ngOnInit() {
    this.supplierForm = new FormGroup({
      name: new FormControl('', Validators.required),
      businessName: new FormControl(''),
      code: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]{4}$/)]),
      iban: new FormControl(''),
      shipsToAmazon: new FormControl(false),
      transit: new FormControl(false),
      canPickupToday: new FormControl(false),
      acceptBindingOrders: new FormControl(false),
      minimalValue: new FormControl({ value: null, disabled: true }),
      minimalShipmentValue: new FormControl({ value: null, disabled: true }),
      score: new FormControl(null, Validators.required),
      updatedCatalog: new FormControl(false),
      uniqueSupplier: new FormControl(false),
      takesBackorder: new FormControl(true),
      takesFirstorder: new FormControl(true),
      takesSoftFirstorder: new FormControl(true),
      ssccPrefix: new FormControl(null, [Validators.pattern(/^[0-9]{3}$/)]),
      sendSSCC: new FormControl(false),
      asnPrefix: new FormControl(null, [Validators.pattern(/^[0-9A-Z]{4}$/)]),
      sendsAsn: new FormControl(false),
      defaultTransitWarehouse: new FormControl(),
      expiringShipmentAlertDaysBefore: new FormControl(0),
      templateWithDestination: new FormControl(false),
      paymentCategoryThreshold: new FormControl(),
      paymentExecutiveThreshold: new FormControl(),
      downconfirmThreshold: new FormControl(),
      paymentTerms: new FormControl(),
      discount: new FormControl(),
      shippingCosts: new FormControl(),
      shipmentDocumentMethod: new FormControl(),
      parentSupplier: new FormControl(),
    });

    this.stockLocForm = new FormGroup({
      name: new FormControl('', Validators.required),
      code: new FormControl('', Validators.required),
      address: new FormControl(),
      countryCode: new FormControl('', Validators.required),
    });

    this.inventoryService.getBrandonTransitWarehouses().subscribe((transitWarehouse: StockLocation[]) => {
      this.transitWarehouse = transitWarehouse;
      this.setTransitWarehouse();
    });

    this.inventoryService.getPackingListFields().subscribe((packingListFields: string[]) => {
      this.packingListFields = packingListFields;
    });

    this.inventoryService.getPaymentTerms().subscribe((paymentTerms: Status[]) => {
      this.paymentTerms = paymentTerms;
      this.setPaymentTerms();
    });

    this.inventoryService.getShipmentDocumentMethods().subscribe((shipmentDocumentMethods: Status[]) => {
      this.shipmentDocumentMethods = shipmentDocumentMethods;
      this.setShipmentDocumentMethod();
    });

    this.inventoryService.getAllSuppliers().subscribe((suppliers: Supplier[]) => {
      this.suppliers = suppliers;
    });

    this.route.params.subscribe((params: Params) => {
      this.supplierId = +params['id'];
      this.editMode = params['id'] != null;
      if (this.editMode) {
        this.inventoryService.getSupplierById(this.supplierId).subscribe((supplier: Supplier) => {
          this.currentSupplier = supplier;
          this.changeFormValues(this.currentSupplier);

          this.setTransitWarehouse();
          this.setPaymentTerms();
          this.setShipmentDocumentMethod();
        });
      }
    });

    this.hasAdministration = this.authorizationService.hasPermission(Permissions.Administration);
    this.hasSupplierThresholdUpdate = this.authorizationService.hasPermission(Permissions.SupplierThresholdUpdate);
    this.hasSupplierThresholdUpdate.subscribe((supplierThresholdUpdate) => {
      if (supplierThresholdUpdate) {
        this.supplierForm.controls.minimalValue.enable();
        this.supplierForm.controls.minimalShipmentValue.enable();
      }
    });
  }

  setTransitWarehouse() {
    if (this.currentSupplier.defaultTransitWarehouse) {
      this.supplierForm.controls['defaultTransitWarehouse'].patchValue(
        this.transitWarehouse.find((it) => it.id === this.currentSupplier.defaultTransitWarehouse.id)
      );
    }
  }

  setPaymentTerms() {
    if (this.currentSupplier.paymentTerms) {
      this.supplierForm.controls['paymentTerms'].patchValue(
        this.paymentTerms.find((it) => it.code === this.currentSupplier.paymentTerms.code)
      );
    }
  }

  setShipmentDocumentMethod() {
    if (this.currentSupplier.shipmentDocumentMethod) {
      this.supplierForm.controls['shipmentDocumentMethod'].patchValue(
        this.shipmentDocumentMethods.find((it) => it.code === this.currentSupplier.shipmentDocumentMethod.code)
      );
    }
  }

  changeFormValues(supplier: Supplier) {
    this.supplierForm.setValue({
      name: supplier.name,
      businessName: supplier.businessName ? supplier.businessName : null,
      code: supplier.code,
      iban: supplier.iban ? supplier.iban : null,
      minimalValue: supplier.minimalValue ? supplier.minimalValue : null,
      minimalShipmentValue: supplier.minimalShipmentValue ? supplier.minimalShipmentValue : null,
      shipsToAmazon: supplier.shipsToAmazon ? supplier.shipsToAmazon : false,
      transit: supplier.transit ? supplier.transit : false,
      canPickupToday: supplier.canPickupToday ? supplier.canPickupToday : false,
      acceptBindingOrders: supplier.acceptBindingOrders ? supplier.acceptBindingOrders : false,
      score: supplier.score ? supplier.score : null,
      updatedCatalog: !isNilty(supplier.updatedCatalog) ? supplier.updatedCatalog : false,
      uniqueSupplier: !isNilty(supplier.uniqueSupplier) ? supplier.uniqueSupplier : false,
      takesBackorder: !isNilty(supplier.takesBackorder) ? supplier.takesBackorder : false,
      takesFirstorder: !isNilty(supplier.takesFirstorder) ? supplier.takesFirstorder : false,
      takesSoftFirstorder: !isNilty(supplier.takesSoftFirstorder) ? supplier.takesSoftFirstorder : false,
      ssccPrefix: !isNilty(supplier.ssccPrefix) ? supplier.ssccPrefix : null,
      sendSSCC: !isNilty(supplier.sendSSCC) ? supplier.sendSSCC : false,
      asnPrefix: !isNilty(supplier.asnPrefix) ? supplier.asnPrefix : null,
      sendsAsn: !isNilty(supplier.sendsAsn) ? supplier.sendsAsn : false,
      defaultTransitWarehouse: !isNilty(supplier.defaultTransitWarehouse)
        ? this.transitWarehouse.find((it) => it.id === supplier.defaultTransitWarehouse.id)
        : null,
      expiringShipmentAlertDaysBefore: supplier.expiringShipmentAlertDaysBefore ?? 0,
      templateWithDestination: supplier.expiringShipmentAlertTemplateWithDestination ?? false,
      paymentCategoryThreshold: supplier.paymentCategoryThreshold ? supplier.paymentCategoryThreshold : null,
      paymentExecutiveThreshold: supplier.paymentExecutiveThreshold ? supplier.paymentExecutiveThreshold : null,
      downconfirmThreshold: supplier.downconfirmThreshold ? supplier.downconfirmThreshold : null,
      paymentTerms: supplier.paymentTerms ? supplier.paymentTerms : null,
      discount: supplier.discount ? supplier.discount : null,
      shippingCosts: supplier.shippingCosts ? supplier.shippingCosts : null,
      shipmentDocumentMethod: supplier.shipmentDocumentMethod ? supplier.shipmentDocumentMethod : null,
      parentSupplier: !isNilty(supplier.parentSupplier) ? this.suppliers.find((it) => it.id === supplier.parentSupplier.id) : null,
    });
    this.sendSSCCChanged({ source: null, checked: supplier.sendSSCC });

    this.shipmentDocumentationReceivers = supplier.shipmentDocumentationReceivers ? supplier.shipmentDocumentationReceivers.split(',') : [];
    this.packingListErrorReceivers = supplier.packingListErrorReceivers ? supplier.packingListErrorReceivers.split(',') : [];
    this.expiringShipmentAlertReceivers = supplier.expiringShipmentAlertReceivers ? supplier.expiringShipmentAlertReceivers.split(',') : [];

    this.categoryManagerReceivers = supplier.categoryManagerReceivers ? supplier.categoryManagerReceivers.split(',') : [];
    this.executiveManagerReceivers = supplier.executiveManagerReceivers ? supplier.executiveManagerReceivers.split(',') : [];
    this.paymentRemittanceReceivers = supplier.paymentRemittanceReceivers ? supplier.paymentRemittanceReceivers.split(',') : [];
  }

  onCancel() {
    this.location.back();
  }

  onSubmit() {
    if (this.editMode) {
      this.currentSupplier.id = this.supplierId;
    } else {
      this.currentSupplier.id = null;
    }

    this.currentSupplier.name = this.supplierForm.controls.name.value;
    this.currentSupplier.businessName = this.supplierForm.controls.businessName.value;
    this.currentSupplier.code = this.supplierForm.controls.code.value.toUpperCase();
    this.currentSupplier.iban = this.supplierForm.controls.iban.value;

    this.currentSupplier.minimalValue = this.supplierForm.controls.minimalValue.value;
    this.currentSupplier.minimalShipmentValue = this.supplierForm.controls.minimalShipmentValue.value;

    this.currentSupplier.shipsToAmazon = this.supplierForm.controls.shipsToAmazon.value;
    this.currentSupplier.transit = this.supplierForm.controls.transit.value;
    this.currentSupplier.canPickupToday = this.supplierForm.controls.canPickupToday.value;
    this.currentSupplier.acceptBindingOrders = this.supplierForm.controls.acceptBindingOrders.value;
    this.currentSupplier.score = this.supplierForm.controls.score.value;
    this.currentSupplier.updatedCatalog = this.supplierForm.controls.updatedCatalog.value
      ? this.supplierForm.controls.updatedCatalog.value
      : false;
    this.currentSupplier.uniqueSupplier = this.supplierForm.controls.uniqueSupplier.value
      ? this.supplierForm.controls.uniqueSupplier.value
      : false;
    this.currentSupplier.takesBackorder = this.supplierForm.controls.takesBackorder.value
      ? this.supplierForm.controls.takesBackorder.value
      : false;
    this.currentSupplier.takesFirstorder = this.supplierForm.controls.takesFirstorder.value
      ? this.supplierForm.controls.takesFirstorder.value
      : false;
    this.currentSupplier.takesSoftFirstorder = this.supplierForm.controls.takesSoftFirstorder.value
      ? this.supplierForm.controls.takesSoftFirstorder.value
      : false;
    this.currentSupplier.ssccPrefix = this.supplierForm.controls.ssccPrefix.value ? this.supplierForm.controls.ssccPrefix.value : undefined;
    this.currentSupplier.sendSSCC = this.supplierForm.controls.sendSSCC.value ? this.supplierForm.controls.sendSSCC.value : false;
    this.currentSupplier.asnPrefix = this.supplierForm.controls.asnPrefix.value ? this.supplierForm.controls.asnPrefix.value : undefined;
    this.currentSupplier.sendsAsn = this.supplierForm.controls.sendsAsn.value ? this.supplierForm.controls.sendsAsn.value : false;
    this.currentSupplier.defaultTransitWarehouse = this.supplierForm.controls.defaultTransitWarehouse.value;

    this.currentSupplier.shipmentDocumentationReceivers = this.shipmentDocumentationReceivers.join(',');
    this.currentSupplier.packingListErrorReceivers = this.packingListErrorReceivers.join(',');
    this.currentSupplier.expiringShipmentAlertReceivers = this.expiringShipmentAlertReceivers.join(',');

    this.currentSupplier.expiringShipmentAlertDaysBefore = this.supplierForm.controls.expiringShipmentAlertDaysBefore.value;
    this.currentSupplier.expiringShipmentAlertTemplateWithDestination = this.supplierForm.controls.templateWithDestination.value;

    this.currentSupplier.paymentCategoryThreshold = this.supplierForm.controls.paymentCategoryThreshold.value;
    this.currentSupplier.paymentExecutiveThreshold = this.supplierForm.controls.paymentExecutiveThreshold.value;

    this.currentSupplier.categoryManagerReceivers = this.categoryManagerReceivers.join(',');
    this.currentSupplier.executiveManagerReceivers = this.executiveManagerReceivers.join(',');
    this.currentSupplier.paymentRemittanceReceivers = this.paymentRemittanceReceivers.join(',');
    this.currentSupplier.downconfirmThreshold = this.supplierForm.controls.downconfirmThreshold.value;

    this.currentSupplier.paymentTerms = this.supplierForm.controls.paymentTerms.value;
    this.currentSupplier.discount = this.supplierForm.controls.discount.value;
    this.currentSupplier.shippingCosts = this.supplierForm.controls.shippingCosts.value;

    this.currentSupplier.shipmentDocumentMethod = this.supplierForm.controls.shipmentDocumentMethod.value;

    this.currentSupplier.parentSupplier = this.supplierForm.controls.parentSupplier.value;

    this.inventoryService.postSupplier(this.currentSupplier.toDTO()).subscribe((resp: Supplier) => {
      if (!isNilty(resp)) {
        this.currentSupplier = resp;
        if (!this.editMode) {
          this.defaultStockLoc.id = null;
          this.defaultStockLoc.name = this.stockLocForm.value.name;
          this.defaultStockLoc.address = this.stockLocForm.value.address;
          this.defaultStockLoc.code = this.stockLocForm.value.code;
          this.defaultStockLoc.countryCode = this.stockLocForm.value.countryCode;
          this.defaultStockLoc.setByDefault = true;
          this.defaultStockLoc.supplier = this.currentSupplier;
          this.inventoryService.postStockLocation(this.defaultStockLoc.toDTO()).subscribe(() => {});
        }

        this.location.back();
      }
    });
  }

  onDelete() {
    const message = 'Deleting rule with supplier: ' + this.currentSupplier.name;

    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '400px',
      data: message,
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.inventoryService
          .deleteSupplier(this.supplierId)
          .toPromise()
          .then((exitStatus: boolean) => {
            if (exitStatus) {
              console.log('Warehouse deleted.');
              this.router.navigate(['inventory', 'suppliers']);
            } else {
              const errorRef = this.dialog.open(GenericErrorModalComponent, {
                width: '350px',
                data: 'This Supplier has associated Products and cannot be deleted.',
              });
            }
          });
      }
    });
  }

  sendSSCCChanged(event: MatCheckboxChange) {
    if (event.checked || this.supplierForm.value.sendSSCC) {
      this.supplierForm.controls.ssccPrefix.setValidators([Validators.required, Validators.pattern(/^[0-9]{3}$/)]);
      this.supplierForm.controls.ssccPrefix.markAsTouched();
    } else {
      this.supplierForm.controls.ssccPrefix.setValidators(Validators.pattern(/^[0-9]{3}$/));
    }
    this.supplierForm.controls.ssccPrefix.updateValueAndValidity();
  }

  sendAsnChanged(event: MatCheckboxChange) {
    if (event.checked || this.supplierForm.value.sendsAsn) {
      if (isNilty(this.supplierForm.controls.asnPrefix.value)) {
        this.supplierForm.controls.asnPrefix.setValue(this.supplierForm.controls.code.value);
      }
      this.supplierForm.controls.asnPrefix.setValidators([Validators.required, Validators.pattern(/^[0-9A-Z]{4}$/)]);
      this.supplierForm.controls.asnPrefix.markAsTouched();
    } else {
      this.supplierForm.controls.asnPrefix.setValidators(Validators.pattern(/^[0-9A-Z]{4}$/));
    }
    this.supplierForm.controls.asnPrefix.updateValueAndValidity();
  }

  editJimboMessages() {
    this.selectedMessageTypes = this.currentSupplier.jimboMessages;
    this.inventoryService.getJimboMessageTypes().subscribe((resp: string[]) => {
      this.jimboMessageTypes = resp;
      this.showJimboMessagesSelect = true;
    });
  }

  saveJimboMessages() {
    this.inventoryService.setJimboMessageTypes(this.supplierId, this.selectedMessageTypes).subscribe(() => {
      this.canSaveMessages = false;
      this.currentSupplier.jimboMessages = [...this.selectedMessageTypes];
    });
  }

  messagesChanged() {
    this.canSaveMessages = true;
  }

  removeEmailReceiver(r: string): void {
    const index = this.shipmentDocumentationReceivers.indexOf(r);

    if (index >= 0) {
      this.shipmentDocumentationReceivers.splice(index, 1);
    }
  }

  addEmailReceiver(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (!isNilty(value)) {
      if (this.receiverEmailInput.valid) {
        this.shipmentDocumentationReceivers.push(value);
        // Reset the input value
        if (input) {
          input.value = '';
          this.receiverEmailInput.reset();
        }
      } else {
        this.snackBar.open(value + ' is not a valid email.')._dismissAfter(2000);
      }
    }
  }

  addPackingListEmailReceiver(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (!isNilty(value)) {
      if (this.receiverPackingListEmailInput.valid) {
        this.packingListErrorReceivers.push(value);
        // Reset the input value
        if (input) {
          input.value = '';
          this.receiverPackingListEmailInput.reset();
        }
      } else {
        this.snackBar.open(value + ' is not a valid email.')._dismissAfter(2000);
      }
    }
  }

  removePackingListEmailReceiver(r: string): void {
    const index = this.packingListErrorReceivers.indexOf(r);

    if (index >= 0) {
      this.packingListErrorReceivers.splice(index, 1);
    }
  }

  addExpiringShipmentAlertReceiver(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (!isNilty(value)) {
      if (this.receiverExpiringShipmentAlert.valid) {
        this.expiringShipmentAlertReceivers.push(value);
        // Reset the input value
        if (input) {
          input.value = '';
          this.receiverExpiringShipmentAlert.reset();
        }
      } else {
        this.snackBar.open(value + ' is not a valid email.')._dismissAfter(2000);
      }
    }
  }

  removeExpiringShipmentAlertReceiver(r: string): void {
    const index = this.expiringShipmentAlertReceivers.indexOf(r);

    if (index >= 0) {
      this.expiringShipmentAlertReceivers.splice(index, 1);
    }
  }

  addCategoryManagerReceiver(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (!isNilty(value)) {
      if (this.receiverCategoryManager.valid) {
        this.categoryManagerReceivers.push(value);
        // Reset the input value
        if (input) {
          input.value = '';
          this.receiverCategoryManager.reset();
        }
      } else {
        this.snackBar.open(value + ' is not a valid email.')._dismissAfter(2000);
      }
    }
  }

  removeCategoryManagerReceiver(r: string): void {
    const index = this.categoryManagerReceivers.indexOf(r);

    if (index >= 0) {
      this.categoryManagerReceivers.splice(index, 1);
    }
  }

  addExecutiveManagerReceiver(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (!isNilty(value)) {
      if (this.receiverExecutiveManager.valid) {
        this.executiveManagerReceivers.push(value);
        // Reset the input value
        if (input) {
          input.value = '';
          this.receiverExecutiveManager.reset();
        }
      } else {
        this.snackBar.open(value + ' is not a valid email.')._dismissAfter(2000);
      }
    }
  }

  removeExecutiveManagerReceiver(r: string): void {
    const index = this.executiveManagerReceivers.indexOf(r);

    if (index >= 0) {
      this.executiveManagerReceivers.splice(index, 1);
    }
  }

  addPaymentRemittanceReceiver(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (!isNilty(value)) {
      if (this.receiverPaymentRemittance.valid) {
        this.paymentRemittanceReceivers.push(value);
        // Reset the input value
        if (input) {
          input.value = '';
          this.receiverPaymentRemittance.reset();
        }
      } else {
        this.snackBar.open(value + ' is not a valid email.')._dismissAfter(2000);
      }
    }
  }

  removePaymentRemittanceReceiver(r: string): void {
    const index = this.paymentRemittanceReceivers.indexOf(r);

    if (index >= 0) {
      this.paymentRemittanceReceivers.splice(index, 1);
    }
  }
}
